import { useQuery } from '@apollo/client';
import type React from 'react';

import { SharePreviewDocument } from '../../graphql/link-preview-share-preview.gql-types';
import type {
  SharePreviewQuery,
  SharePreviewQueryVariables,
} from '../../graphql/link-preview-share-preview.gql-types';
import { PreviewError } from '../preview-error/preview-error';
import { PreviewSkeleton } from '../preview-skeleton/preview-skeleton';
import { PreviewContent } from '../section-components/preview-content/preview-content';

export type PreviewQueryType = {
  onCompleted: (data: SharePreviewQuery) => void;
  onError: () => void;
  url: string;
};

export const PreviewQuery: React.FC<PreviewQueryType> = ({
  onCompleted,
  onError,
  url,
}) => {
  const { data, loading, refetch } = useQuery<
    SharePreviewQuery,
    SharePreviewQueryVariables
  >(SharePreviewDocument, {
    onCompleted,
    onError,
    variables: { url },
    notifyOnNetworkStatusChange: true,
  });

  if (loading) return <PreviewSkeleton />;

  if (!data?.viewer?.linkPreview?.success)
    return <PreviewError {...{ refetch }} />;

  const {
    description,
    sourceDomain,
    title,
    cachedImageUrl,
    metadata: { sourceActor } = {},
  } = data.viewer.linkPreview.success;

  return (
    <PreviewContent
      {...{
        cachedImageUrl: cachedImageUrl || '',
        description: description || '',
        sourceDomain: sourceDomain || '',
        title: title || '',
        sourceActor: sourceActor || undefined,
      }}
    />
  );
};
