import React from 'react';
import { v4 as uuid } from 'uuid';

import { Button } from '@xing-com/button';
import {
  ShareSuccessBanner,
  useSocialBanner,
} from '@xing-com/crate-social-common-share';
import type { BackendTrackingMetaData } from '@xing-com/crate-social-types';
import { useWrapRedirectLoggedOutUserOnClick } from '@xing-com/crate-social-utils';
import { IconShare } from '@xing-com/icons';
import { OmViewGridLess } from '@xing-com/platform-layout-om';

import { ShareTabView } from '../share-tab-view/share-tab-view';

export type ShareTabOmViewType = {
  backendTrackingMetaData?: BackendTrackingMetaData;
  buttonClassName?: string;
  entryPoint?: string;
  interactionTargetUrn?: string;
  onButtonClick?: () => void;
  onCancel?: () => void;
  onError?: () => void;
  onSuccess?: () => void;
  propChannel?: string;
  propSourceType?: string;
  trigger?: (triggerOmView: any) => JSX.Element;
  url: string;
  verb?: 'SHARE' | 'POST';
};

export const ShareTabOmView: React.FC<ShareTabOmViewType> = ({
  backendTrackingMetaData = { trackingTokens: [], page: '', position: '' },
  buttonClassName,
  entryPoint = '',
  interactionTargetUrn = '',
  onButtonClick = () => undefined,
  onCancel = () => undefined,
  onError = () => undefined,
  onSuccess = () => undefined,
  propChannel = '',
  propSourceType = '',
  trigger,
  url,
  verb = 'POST',
}) => {
  const propSocialInteractionId = React.useRef(uuid()).current;
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const { bannerProps, setBannerProps } = useSocialBanner();

  const { handleClick: handleRedirectLoggedOutUser } =
    useWrapRedirectLoggedOutUserOnClick();

  const onTriggerClick = (
    e: { currentTarget: HTMLElement | undefined } | undefined,
    triggerOmView: (
      e?: { currentTarget: HTMLElement | undefined } | undefined
    ) => void
  ): void => {
    setBannerProps({ show: false });
    onButtonClick();
    handleRedirectLoggedOutUser();
    triggerOmView(e);
  };

  return (
    <React.Fragment>
      <OmViewGridLess
        preventPropagation
        onClose={() => {
          buttonRef?.current?.focus();
          onCancel();
        }}
        trigger={(triggerOmView) =>
          trigger?.(
            (e: { currentTarget: HTMLElement | undefined } | undefined) =>
              onTriggerClick(e, triggerOmView)
          ) || (
            <Button
              innerRef={buttonRef}
              onClick={(e) => onTriggerClick(e, triggerOmView)}
              icon={IconShare}
              className={buttonClassName}
            />
          )
        }
      >
        {({ handleClose }) => (
          <ShareTabView
            {...{
              backendTrackingMetaData,
              entryPoint,
              interactionTargetUrn,
              onButtonClick,
              onError,
              propChannel,
              propSocialInteractionId,
              propSourceType,
              url,
              verb,
            }}
            onCancel={() => {
              onCancel();
              handleClose();
            }}
            onSuccess={({ redirectUrl, targetType }) => {
              setBannerProps({ redirectUrl, show: true, targetType });
              onSuccess();
              handleClose();
            }}
          />
        )}
      </OmViewGridLess>
      <ShareSuccessBanner {...{ ...bannerProps, setBannerProps }} />
    </React.Fragment>
  );
};
