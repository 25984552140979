import styled, { css } from 'styled-components';

import {
  scale000,
  scale120,
  spaceL,
  spaceM,
  spaceXL,
  xdlColorBackgroundSecondary,
  xdlColorTextSecondary,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

// *** Base ***
const basePreviewErrorWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${spaceXL};
  background: ${xdlColorBackgroundSecondary};
  margin-top: ${scale120};
`;

const baseErrorMessage = css`
  color: ${xdlColorTextSecondary};
  margin: ${spaceM} ${scale000} ${spaceL};
`;

// *** Components ***
const PreviewErrorWrapper = styled.div`
  ${basePreviewErrorWrapper}
`;

const ErrorMessage = styled(BodyCopy)`
  ${baseErrorMessage}
`;

export const Styled = {
  PreviewErrorWrapper,
  ErrorMessage,
};
