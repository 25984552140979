import type React from 'react';

import { PreviewContext } from '../../contexts';
import type { SharePreviewQuery } from '../../graphql';
import { PreviewQuery } from '../preview-query/preview-query';

export type PreviewType = {
  className?: string;
  imageMaxHeight?: string;
  imageUrl?: string;
  onClose?: () => void;
  onCompleted?: (data: SharePreviewQuery) => void;
  onError?: () => void;
  url: string;
};

export const Preview: React.FC<PreviewType> = ({
  className,
  imageMaxHeight = '',
  imageUrl = '',
  onClose,
  onCompleted = () => undefined,
  onError = () => undefined,
  url,
}) => (
  <PreviewContext.Provider
    value={{ imageMaxHeight, imageUrl, className, onClose }}
  >
    {url && <PreviewQuery {...{ onCompleted, onError, url }} />}
  </PreviewContext.Provider>
);
