import styled, { css } from 'styled-components';

import { ProfileImage } from '@xing-com/profile-image';
import { spaceS } from '@xing-com/tokens';

// *** Base ***
const baseWrapper = css`
  align-items: center;
  display: flex;
`;

const baseUserImage = css`
  margin-right: ${spaceS};
`;

// *** Components ***
const Wrapper = styled.div`
  ${baseWrapper}
`;

const UserImage = styled(ProfileImage)`
  ${baseUserImage}
`;

export const Styled = {
  Wrapper,
  UserImage,
};
