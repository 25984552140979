import React from 'react';

export type PreviewContextType = {
  imageMaxHeight: string;
  imageUrl: string;
  className?: string;
  onClose?: () => void;
};

export const PreviewContext = React.createContext<PreviewContextType>({
  imageMaxHeight: '',
  imageUrl: '',
  className: '',
  onClose: () => undefined,
});
