import type React from 'react';

import { Headline } from '@xing-com/typography';

import { Styled } from './user-info.styles';

export type UserInfoType = {
  displayName: string;
  image?: string;
};

export const UserInfo: React.FC<UserInfoType> = ({ displayName, image }) => (
  <Styled.Wrapper>
    <Styled.UserImage size="xxsmall" profileName={displayName} src={image} />
    <Headline ellipsis noMargin size="small">
      {displayName}
    </Headline>
  </Styled.Wrapper>
);
