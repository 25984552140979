import styled from 'styled-components';

import { Button as InnerButton } from '@xing-com/button';
import { spaceS } from '@xing-com/tokens';

export const Button = styled(InnerButton)`
  position: absolute;
  right: ${spaceS};
  top: ${spaceS};
`;
