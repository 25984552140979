import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';

import type {
  OnErrorType,
  OnSuccessType,
} from '@xing-com/crate-social-common-share';
import type { BackendTrackingMetaData } from '@xing-com/crate-social-types';
import { Divider } from '@xing-com/divider';
import { IconMessages, IconButterflyMono } from '@xing-com/icons';
import { motionTimeNumberS } from '@xing-com/tokens';

import { ShareViews } from '../../sections';

import { shareViewTransitionName, Styled } from './share-tab-view.styles';

const isItFeed = {
  true: {
    shareTo: 'MESSENGER' as const,
    icon: <IconMessages height={24} width={48} />,
    messageId: 'SHARE_USER_SUBTEXT_MESSAGE',
  },
  false: {
    shareTo: 'FEED' as const,
    icon: <IconButterflyMono height={24} width={48} />,
    messageId: 'SHARE_USER_SUBTEXT_STARTPAGE',
  },
};

export type ShareTabViewType = {
  backendTrackingMetaData: BackendTrackingMetaData;
  entryPoint: string;
  interactionTargetUrn: string;
  onCancel: () => void;
  onError: OnErrorType;
  onSuccess: OnSuccessType;
  openWithShareTo?: '' | 'MESSENGER' | 'FEED';
  propChannel: string;
  propSocialInteractionId: string;
  propSourceType: string;
  url: string;
  verb: 'SHARE' | 'POST';
};

export const ShareTabView: React.FC<ShareTabViewType> = ({
  backendTrackingMetaData,
  entryPoint,
  interactionTargetUrn,
  onCancel,
  onError,
  onSuccess,
  openWithShareTo = 'MESSENGER',
  propChannel,
  propSocialInteractionId,
  propSourceType,
  url,
  verb,
}) => {
  const [selectedId, setSelected] = React.useState(openWithShareTo);
  const [interMed, setInterMed] = React.useState(openWithShareTo);
  const [animated, setAnimated] = React.useState(true);

  const isFeed = String(selectedId === 'FEED') as 'true' | 'false';

  return (
    <CSSTransition
      in={animated}
      timeout={parseInt(motionTimeNumberS)}
      classNames={shareViewTransitionName}
      onExited={() => {
        setAnimated(true);
        setSelected(interMed);
      }}
    >
      <Styled.Wrapper>
        <ShareViews
          openWithShareTo={selectedId}
          {...{
            backendTrackingMetaData,
            entryPoint,
            interactionTargetUrn,
            onCancel,
            onError,
            onSuccess,
            propChannel,
            propSocialInteractionId,
            propSourceType,
            url,
            verb,
          }}
        />
        <Divider />
        <Styled.Button
          onClick={() => {
            setInterMed(isItFeed[isFeed].shareTo);
            setAnimated(false);
            window.scrollTo(0, 0);
          }}
        >
          {isItFeed[isFeed].icon}
          <FormattedMessage id={isItFeed[isFeed].messageId} />
          <Styled.IconArrow height={24} />
        </Styled.Button>
        <Divider />
      </Styled.Wrapper>
    </CSSTransition>
  );
};
