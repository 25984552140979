import type React from 'react';
import TruncateMarkup from 'react-truncate-markup';

import { UserInfo, ShowMoreEllipsis } from '../..';

import { Styled } from './preview-actor.styles';

export type PreviewActorType = {
  hasLinkImage: boolean;
  image?: string | null;
  actorComment?: string;
  title?: string;
};

export const PreviewActor: React.FC<PreviewActorType> = ({
  hasLinkImage,
  image,
  actorComment,
  title: displayName,
}) =>
  displayName || actorComment ? (
    <Styled.SourceActor $hasLinkImage={hasLinkImage}>
      {displayName && (
        <UserInfo {...{ displayName }} image={image || undefined} />
      )}
      {actorComment && (
        <Styled.SourceActorText size="small">
          <TruncateMarkup lines={3} ellipsis={<ShowMoreEllipsis />}>
            <span>{actorComment}</span>
          </TruncateMarkup>
        </Styled.SourceActorText>
      )}
    </Styled.SourceActor>
  ) : null;
