import styled, { css } from 'styled-components';

import {
  scale000,
  spaceL,
  spaceXS,
  spaceXXS,
  xdlColorTextSecondary,
} from '@xing-com/tokens';
import { BodyCopy, Headline } from '@xing-com/typography';

// *** Base ***
const baseFooter = css`
  padding: ${spaceL};
`;

const baseFooterTitle = css`
  margin-bottom: ${spaceXXS};
`;

const baseFooterDescription = css`
  hyphens: auto;
  margin: ${spaceXXS} ${scale000} ${scale000};
`;

const baseSourceDomain = css`
  color: ${xdlColorTextSecondary};
  margin: ${spaceXS} ${scale000} ${scale000};
`;

// *** Components ***
const Footer = styled.div<{ $hasActorAndNoImage: boolean }>`
  ${baseFooter}
  ${({ $hasActorAndNoImage }) =>
    $hasActorAndNoImage && `padding-top: ${scale000}`}
`;

const FooterTitle = styled(Headline)`
  ${baseFooterTitle}
`;

const FooterDescription = styled(BodyCopy)`
  ${baseFooterDescription}
`;

const SourceDomain = styled(BodyCopy)`
  ${baseSourceDomain}
`;

export const Styled = {
  Footer,
  FooterTitle,
  FooterDescription,
  SourceDomain,
};
