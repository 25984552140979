/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SharePreviewQueryVariables = Types.Exact<{
  url: Types.Scalars['URL']['input'];
}>;

export type SharePreviewQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    id: string;
    linkPreview?: {
      __typename?: 'LinkPreviewResult';
      success?: {
        __typename?: 'LinkPreview';
        title?: string | null;
        description?: string | null;
        sourceDomain?: string | null;
        cachedImageUrl?: string | null;
        metadata:
          | {
              __typename?: 'CompanyPreviewMetadata';
              sourceActor?: {
                __typename?: 'LinkPreviewSourceActor';
                title: string;
                subtitle?: string | null;
                image?: string | null;
                message?: string | null;
              } | null;
            }
          | {
              __typename?: 'FeedyPreviewMetadata';
              sourceActor?: {
                __typename?: 'LinkPreviewSourceActor';
                title: string;
                subtitle?: string | null;
                image?: string | null;
                message?: string | null;
              } | null;
            }
          | {
              __typename?: 'JobPreviewMetadata';
              sourceActor?: {
                __typename?: 'LinkPreviewSourceActor';
                title: string;
                subtitle?: string | null;
                image?: string | null;
                message?: string | null;
              } | null;
            }
          | {
              __typename?: 'LinkPreviewMetadata';
              sourceActor?: {
                __typename?: 'LinkPreviewSourceActor';
                title: string;
                subtitle?: string | null;
                image?: string | null;
                message?: string | null;
              } | null;
            }
          | {
              __typename?: 'ProfilePreviewMetadata';
              sourceActor?: {
                __typename?: 'LinkPreviewSourceActor';
                title: string;
                subtitle?: string | null;
                image?: string | null;
                message?: string | null;
              } | null;
            }
          | {
              __typename?: 'VideoPreviewMetadata';
              sourceActor?: {
                __typename?: 'LinkPreviewSourceActor';
                title: string;
                subtitle?: string | null;
                image?: string | null;
                message?: string | null;
              } | null;
            };
      } | null;
    } | null;
  } | null;
};

export const SharePreviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SharePreview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'url' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'URL' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkPreview' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'url' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'url' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'success' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sourceDomain' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cachedImageUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'sourceActor',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'title',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'subtitle',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'image',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'message',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SharePreviewQuery, SharePreviewQueryVariables>;
