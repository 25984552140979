import type React from 'react';

import { IconCross } from '@xing-com/icons';

import * as Styled from './preview-close-button.styled';

export type PreviewCloseButtonType = {
  onClose: () => void;
};

export const PreviewCloseButton: React.FC<PreviewCloseButtonType> = ({
  onClose,
}) => (
  <Styled.Button
    icon={IconCross}
    onClick={onClose}
    size="small"
    variant="overlay"
  />
);
