import type React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@xing-com/button';
import { IllustrationEmptyStateError } from '@xing-com/illustrations';

import { Styled } from './preview-error.styles';

export type PreviewErrorType = {
  refetch: () => void;
};

export const PreviewError: React.FC<PreviewErrorType> = ({ refetch }) => (
  <Styled.PreviewErrorWrapper>
    <IllustrationEmptyStateError />
    <Styled.ErrorMessage>
      <FormattedMessage id="SHARE_PREVIEW_ERROR_HEADLINE" />
    </Styled.ErrorMessage>
    <Button onClick={refetch} variant="tertiary">
      <FormattedMessage id="SHARE_FETCH_ERROR_RETRY" />
    </Button>
  </Styled.PreviewErrorWrapper>
);
