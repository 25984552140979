import styled, { css } from 'styled-components';

import { Card } from '@xing-com/card';
import type { CardProps } from '@xing-com/card';

type StyledProps = {
  Card: React.ComponentType<CardProps>;
  Image: typeof Image;
};

// *** Base ***
const baseCard = css`
  overflow: hidden;
`;

const baseImage = css`
  display: block;
  width: 100%;
  object-fit: cover;
`;

// *** Components ***
const StyledCard = styled(Card)`
  ${baseCard}
`;

const Image = styled.img<{ $imageMaxHeight: string }>`
  ${baseImage}
  ${({ $imageMaxHeight }) =>
    $imageMaxHeight && `max-height: ${$imageMaxHeight}`}
`;

export const Styled: StyledProps = {
  Card: StyledCard,
  Image,
};
