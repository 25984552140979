import * as React from 'react';

import type { BackendTrackingMetaData } from '@xing-com/crate-social-types';

export type TrackingPropsContextType = {
  backendTrackingMetaData: BackendTrackingMetaData;
  entryPoint: string;
  inputHintClick: boolean;
  interactionTargetUrn: string;
  propChannel: string;
  propSocialInteractionId: string;
  propSourceType: string;
};

export const TrackingPropsContext =
  React.createContext<TrackingPropsContextType>({
    backendTrackingMetaData: { trackingTokens: [], page: '', position: '' },
    entryPoint: '',
    inputHintClick: false,
    interactionTargetUrn: '',
    propChannel: '',
    propSocialInteractionId: '',
    propSourceType: '',
  });
