import type React from 'react';
import TruncateMarkup from 'react-truncate-markup';

import { Styled } from './preview-footer.styles';

export type PreviewFooterType = {
  description?: string | null;
  sourceDomain: string;
  title: string;
  hasActorAndNoImage: boolean;
};

export const PreviewFooter: React.FC<PreviewFooterType> = ({
  description,
  sourceDomain,
  title,
  hasActorAndNoImage,
}) => (
  <Styled.Footer $hasActorAndNoImage={hasActorAndNoImage}>
    <Styled.FooterTitle size="small">
      <TruncateMarkup lines={3} ellipsis={'...'}>
        <span>{title}</span>
      </TruncateMarkup>
    </Styled.FooterTitle>
    {description && (
      <Styled.FooterDescription size="small">
        <TruncateMarkup lines={2} ellipsis={'...'}>
          <span>{description}</span>
        </TruncateMarkup>
      </Styled.FooterDescription>
    )}
    <Styled.SourceDomain ellipsis size="small">
      {sourceDomain}
    </Styled.SourceDomain>
  </Styled.Footer>
);
