import React from 'react';

import { PreviewFooter, PreviewActor } from '..';
import { PreviewContext } from '../../../contexts';
import { PreviewCloseButton } from '../../preview-close-button/preview-close-button';

import { Styled } from './preview-content.styles';

export type PreviewContentType = {
  description: string;
  cachedImageUrl: string;
  sourceDomain: string;
  title: string;
  sourceActor?: {
    subtitle?: string | null;
    message?: string | null;
    image?: string | null;
    title?: string;
  };
};

export const PreviewContent: React.FC<PreviewContentType> = ({
  description,
  cachedImageUrl,
  sourceActor,
  sourceDomain,
  title,
}) => {
  const { imageMaxHeight, imageUrl, className, onClose } =
    React.useContext(PreviewContext);

  const image = imageUrl || cachedImageUrl || '';

  return (
    <Styled.Card
      noPadding
      {...{ className }}
      data-testid="link-preview-content"
    >
      <PreviewActor
        {...(sourceActor && sourceActor)}
        actorComment={(!title && description) || undefined}
        hasLinkImage={!!image}
      />
      {image && (
        <Styled.Image
          src={image}
          $imageMaxHeight={imageMaxHeight}
          data-testid="link-preview-image"
        />
      )}
      {title && sourceDomain && (
        <PreviewFooter
          {...{
            description,
            sourceDomain,
            title,
            hasActorAndNoImage: !image && !!sourceActor,
          }}
        />
      )}
      {onClose && <PreviewCloseButton {...{ onClose }} />}
    </Styled.Card>
  );
};
