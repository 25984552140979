import styled, { css } from 'styled-components';

import { scale000, spaceL, spaceM, spaceS } from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

// *** Base ***
const baseSourceActor = css`
  padding: ${spaceL} ${spaceL} ${spaceM};
`;

const baseSourceActorText = css`
  margin: ${spaceS} ${scale000} ${scale000};
`;

// *** Components ***
const SourceActor = styled.div<{ $hasLinkImage: boolean }>`
  ${baseSourceActor}
  ${({ $hasLinkImage }) => !$hasLinkImage && `padding-bottom: ${spaceL}`}
`;

const SourceActorText = styled(BodyCopy)`
  ${baseSourceActorText}
`;

export const Styled = {
  SourceActor,
  SourceActorText,
};
