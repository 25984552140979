import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { spaceM } from '@xing-com/tokens';
import { Headline } from '@xing-com/typography';

export const ShowMoreEllipsis: React.FC = () => (
  <React.Fragment>
    {'… '}
    <StyledShowMore size="small">
      <FormattedMessage id="SOCIAL_SHOW_MORE" />
    </StyledShowMore>
  </React.Fragment>
);

const StyledShowMore = styled(Headline)`
  display: inline;
  margin-bottom: ${spaceM};
`;
